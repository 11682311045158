// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-besucherinfos-en-js": () => import("./../../../src/pages/besucherinfos.en.js" /* webpackChunkName: "component---src-pages-besucherinfos-en-js" */),
  "component---src-pages-besucherinfos-js": () => import("./../../../src/pages/besucherinfos.js" /* webpackChunkName: "component---src-pages-besucherinfos-js" */),
  "component---src-pages-bibliography-en-js": () => import("./../../../src/pages/bibliography.en.js" /* webpackChunkName: "component---src-pages-bibliography-en-js" */),
  "component---src-pages-bibliography-js": () => import("./../../../src/pages/bibliography.js" /* webpackChunkName: "component---src-pages-bibliography-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-impressum-en-js": () => import("./../../../src/pages/impressum.en.js" /* webpackChunkName: "component---src-pages-impressum-en-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projekt-virtulleum-en-js": () => import("./../../../src/pages/projekt-virtulleum.en.js" /* webpackChunkName: "component---src-pages-projekt-virtulleum-en-js" */),
  "component---src-pages-projekt-virtulleum-js": () => import("./../../../src/pages/projekt-virtulleum.js" /* webpackChunkName: "component---src-pages-projekt-virtulleum-js" */),
  "component---src-pages-stadtgeschichte-en-js": () => import("./../../../src/pages/stadtgeschichte.en.js" /* webpackChunkName: "component---src-pages-stadtgeschichte-en-js" */),
  "component---src-pages-stadtgeschichte-js": () => import("./../../../src/pages/stadtgeschichte.js" /* webpackChunkName: "component---src-pages-stadtgeschichte-js" */)
}

